.button {
  color: #fff;
  background-color: $eiss-primary;
  border-radius: 25px;
  padding: 12px 20px;
  border: none;
  font-size: .9em;
  font-weight: 700;

  &:hover {
    background: #c4575a;
  }
}

.RadGrid_Silk .rgEditForm .button {
  color: #fff;
}

.button--line {
    color: #7e7e7e;
    background: #fff;
    border: 1px solid #c8c8c8;

    &:hover {        
        background: #eee;
    }
}

.button--text {
  color: $link-blue;
  background: none;
  border: none;

  &:hover {
    color: $link-blue;
    text-decoration: underline;
    background: none;
  }
}

.button--quickview {
  background: $eiss-primary;

  &:hover {
    background: $eiss-primary-dark;
  }
}

.button--pending {
  &::after {
    background-color: $eiss-primary;
  }
}

.button--small {
  padding: 6px 12px;
  font-size: 13px;
}


