.home-contact {
  max-width: 1000px;
  margin: 0 auto;
  color: #6d6e70;
}

.home-contact__inner {
  overflow: hidden;
  text-align: center;
}

.home-contact__title {
  margin: 0 0 40px;
  padding-top: 60px;
  font-weight: 100;
  font-size: 2.5em;
  border-top: 1px solid #000;
  text-transform: capitalize;
  max-width: 1200px;
}

.home-contact__section {
  position: relative;
  float: left;
  width: 33.3333333%;
  line-height: 20px;
}

.home-contact__content {
  font-size: 16px;
  transform: translateY(-50%);
  color:  #1F1F1F;  
}

.home-contact__content a {
  color:  #1F1F1F;
}

.i--pin,
.i--phone,
.i--laptop {
  display: none;
  display: block;
  height: 71px;
  background: url("/content/themes/eiss/images/home-sprite.png") no-repeat 0 0;
}

.i--pin {
  display: none;
  width: 47px;
}

.i--phone {
  display: none;
  width: 55px;
  background-position: -47px 0;
}

.i--laptop {
  display: none;
  width: 92px;
  background-position: -102px 0;
}

@media only screen and (max-width: 820px) {
  .i--pin,
  .i--phone,
  .i--laptop {
    height: 50px;
    background-size: 137px 50px;
  }

  .i--pin {
    width: 33px;
  }

  .i--phone {
    width: 39px;
    background-position: -33px 0;
  }

  .i--laptop {
    width: 65px;
    background-position: -72px 0;
  }

  .home-contact__content {
    left: 75px;
  }
}

@media only screen and (max-width: 700px) {
  .home-contact {
    margin: 0;
  }

  .home-contact__content{
    .list--plain {
      padding-bottom: 10px;
    }
  }

  .home-contact__section {
    width: 50%;
    margin: 0 0 15px;
  }

  .home-content__section:nth-child(odd) {
    clear: both;
  }  
}

@media only screen and (max-width: 500px) {
  .home-contact__section {
    float: none;
    width: auto;
  }
}
