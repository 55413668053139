/* albert-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 400;
  src: url("/content/themes/eiss/fonts/albert-sans-v1-latin-regular.woff2") format("woff2"), url("/content/themes/eiss/fonts/albert-sans-v1-latin-regular.woff") format("woff");
}

/* albert-sans-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 700;
  src: url("/content/themes/eiss/fonts/albert-sans-v1-latin-700.woff2") format("woff2"), url("/content/themes/eiss/fonts/albert-sans-v1-latin-700.woff") format("woff");
}

body {
  font-family: "Albert Sans", sans-serif;
  color: #1f1f1f;
}

h2 {
  font-size: 26px;
}

.main-banner-wrap .bx-controls-direction {
  display: block;
}

.button {
  color: #fff;
  background-color: #B62226;
  border-radius: 25px;
  padding: 12px 20px;
  border: none;
  font-size: .9em;
  font-weight: 700;
}

.button:hover {
  background: #c4575a;
}

.RadGrid_Silk .rgEditForm .button {
  color: #fff;
}

.button--line {
  color: #7e7e7e;
  background: #fff;
  border: 1px solid #c8c8c8;
}

.button--line:hover {
  background: #eee;
}

.button--text {
  color: #005ca0;
  background: none;
  border: none;
}

.button--text:hover {
  color: #005ca0;
  text-decoration: underline;
  background: none;
}

.button--quickview {
  background: #B62226;
}

.button--quickview:hover {
  background: #770c11;
}

.button--pending::after {
  background-color: #B62226;
}

.button--small {
  padding: 6px 12px;
  font-size: 13px;
}

.site-footer__item .i--twitter,
.site-footer__item .i--facebook,
.site-footer__item .i--linkedin,
.site-footer__item .i--pinterest,
.site-footer__item .i--youtube,
.site-footer__item .i--wordpress,
.site-footer__item .i--feed,
.site-footer__item .i--instagram,
.site-footer__item .i--bangstyle,
.site-footer__item .i--email {
  background: url("/content/themes/eiss/images/social-icons.png") no-repeat;
  background-color: #fff;
  background-size: 52px;
}

.site-footer__item .i--facebook {
  background-position: 0px 0;
}

.site-footer__item .i--instagram {
  background-position: -26px 0;
}

.top-header {
  background-color: #f2f2f2;
}

.top-header__inner {
  display: grid;
  max-width: 1200px;
  margin: 0 auto;
}

.header-top__note {
  display: none;
}

.utility-bar {
  grid-row: 1;
  grid-column: 2;
  background-color: unset;
}

.header-top__note--mobile {
  grid-row: 3;
  grid-column: 1;
  padding: 5px 0 5px 20px;
  text-align: left;
  font-weight: bold;
  font-size: 13px;
}

.is--logged-in .header-top__note--mobile {
  padding-top: 0px;
}

.header-top__countdown {
  grid-row: 3;
  grid-column: 2;
  width: 100%;
  text-align: right;
  padding: 0 20px 0 0;
}

.utility-bar__inner {
  grid-template-columns: 1fr auto auto;
  align-items: center;
  max-width: 1200px;
  padding: 0 12px;
}

.utility-bar__inner a {
  color: #1f1f1f;
}

.login-links {
  grid-column: 2;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.account-header {
  grid-column: 2;
  background-color: unset;
  color: #000;
}

.header-cart {
  grid-column: 3;
}

.login-links,
.header-cart {
  margin-right: 8px;
}

.account-header__icon {
  grid-column: 2;
  grid-row: 1;
  display: block;
  justify-self: right;
}

.account-header__icon .icon-person .icon-circle {
  stroke: #000;
  stroke-width: 1;
}

.account-header__icon .icon-person .icon-body,
.account-header__icon .icon-person .icon-head,
.account-header__icon .account-header__toggle {
  fill: #000;
}

.account-header__value {
  color: #fff;
}

.account-header__toggle {
  display: block;
  justify-self: left;
}

.header-cart__icon svg,
.account-header__toggle svg {
  fill: #000;
}

.header-cart__value {
  color: #fff;
}

.account-header__nav {
  background-color: #f2f2f2;
  color: #1f1f1f;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.24);
}

.header-top-sites-container {
  grid-row: 1;
  grid-column: 1;
}

.header-top-site-items {
  display: flex;
  justify-content: start;
  align-items: center;
}

.header-top-site-item {
  margin: 0 20px;
  font-size: 1.4em;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-top-site-item a {
  color: #1f1f1f;
}

.parent-site {
  font-family: "Albert Sans", sans-serif;
  text-transform: lowercase;
  font-weight: 700;
  font-size: 23px;
}

.beautyconnection-site {
  background: #2a5765;
  padding: 8px 16px;
  margin: 0;
  font-size: 17px;
}

.beautyconnection-site a {
  color: #fff;
}

.beautyconnection-site span {
  font-family: "Brandon Grotesque W01 Bold", sans-serif;
  font-weight: bold;
}

.header-site-link__sub {
  font-style: italic;
  font-size: 12px;
  font-weight: normal;
  margin: 0px;
  margin-top: -5px;
  letter-spacing: 0;
}

.header-top-site__text {
  display: none;
}

.site-logo--mobile {
  display: none;
}

.header-banner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1200px;
  padding: 20px 0;
}

.site-logo {
  padding: 0;
  margin: 0 auto;
  max-width: 120px;
  grid-column: 2;
}

.header-search {
  grid-column: 3;
}

.header-banner .header-search {
  width: auto;
  max-width: 200px;
  justify-self: end;
}

.search__options {
  display: none;
}

.search {
  border-radius: 50px;
}

.search__field {
  height: 30px;
  padding: 10px 0px 7px;
}

.search__text {
  padding: 0 20px;
  border-left: none;
}

.button--search {
  background: url("/Content/themes/eiss/images/search-icon.jpg") no-repeat 0 0;
  background-size: 15px 15px;
  height: 15px;
}

.search__submit {
  flex: 0 0 20px;
}

@media only screen and (min-width: 820px) {
  .title-wrap {
    padding-top: 6em;
  }
  .is--logged-in .title-wrap {
    padding-top: 7.5em;
  }
}

@media only screen and (max-width: 819.98px) {
  .account-header__nav {
    background-color: #aaaaaa;
  }
  .account-header__nav a {
    color: #fff;
  }
}

@media only screen and (max-width: 620px) {
  .top-header {
    background-color: unset;
  }
  .header-top__note--mobile {
    width: 100%;
    grid-column: 1 / span 2;
    text-align: center;
    padding: 8px;
    background-color: #B62226;
    color: #fff;
  }
  .is--logged-in .header-top__note--mobile {
    padding-top: 8px;
  }
  .locator-feature {
    grid-row: 2;
  }
  .header-top-sites-container {
    grid-row: 4;
    grid-column: 1 / span 2;
  }
  .header-top-site-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .header-top-site-item {
    flex: 1 1 50%;
    text-align: center;
    margin: 0;
  }
  .parent-site {
    background-color: #f2f2f2;
  }
  .header-top__countdown {
    grid-row: 5;
    grid-column: 1 / span 2;
    text-align: center;
    color: unset;
    padding: 5px 0;
  }
  .site-logo {
    display: none;
  }
  .site-logo--mobile {
    grid-row: 6;
    padding: 20px 10px 5px;
    margin: 0 auto;
    display: block;
    grid-column: 1 / span 2;
  }
  .site-logo--mobile .site-logo__img {
    margin: 0 auto;
    width: 75%;
  }
  .utility-bar {
    grid-row: 7;
    grid-column: 1 / span 2;
    background-color: #fff;
    padding: 0px;
  }
  .utility-bar__inner {
    grid-template-columns: 80% 20%;
    justify-items: center;
    padding: 0;
  }
  .utility-bar__inner a {
    color: #000;
  }
  .account-header {
    width: 100%;
    grid-column: 1;
    background-color: #fff;
  }
  .account-header__icon {
    grid-column: 1;
  }
  .account-header__nav a {
    color: #fff;
  }
  .header-cart {
    grid-column: 2;
    justify-self: end;
  }
  .account-header__account-greeting {
    display: none;
  }
  .account-header__header-i-guess {
    display: grid;
    grid-template-columns: auto auto 75%;
    align-items: center;
  }
  .account-header__toggle {
    grid-column: 2;
    grid-row: 1;
    padding: 5px 10px 0 5px;
  }
  .account-header__title {
    grid-column: 3;
    text-align: left;
  }
  .search {
    display: none;
  }
  .title-wrap,
  .is--logged-in .title-wrap,
  .header-banner {
    padding: 0px;
  }
  .login-links {
    grid-column: 1;
    justify-self: start;
    padding-left: 20px;
    color: #000;
  }
  .header-search {
    padding-right: 10px;
    width: 100%;
    grid-column: 2;
  }
  .search,
  .search__text {
    max-width: unset;
  }
  .search__field {
    padding: 10px;
  }
}

.site-nav__item--holiday a {
  color: #B62226;
}

@media only screen and (min-width: 820px) {
  .site-nav {
    background: none;
    max-width: 1500px;
  }
  .site-nav__top {
    color: #1f1f1f;
    text-align: center;
  }
  .site-nav__item--promotions a {
    color: #B62226;
  }
  .sub-nav__col, .sub-nav__col--2, .sub-nav__col--3, .sub-nav__col--4 {
    float: left;
    width: 132px;
  }
}

.menu-wrap .search {
  display: none;
}

.nav-toggles,
.nav-toggle {
  background-color: #fff;
  justify-self: center;
}

.burger {
  width: 30px;
  height: 23px;
}

.burger__middle {
  background: #000;
  top: 10px;
}

.burger::before,
.burger::after {
  background: #000;
}

@media only screen and (max-width: 620px) {
  .menu-wrap {
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding: 10px 0;
  }
  .menu-wrap .search {
    display: flex;
  }
}

.catalogs {
  max-width: 1200px;
  margin: 0 auto;
}

.catalogs__header-inner {
  display: inline-block;
  position: relative;
  top: 10px;
  width: 380px;
  height: 60px;
  background: url("/content/themes/eiss/redesign/images/your-next-move.png") no-repeat 0 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.catalog__text::after {
  display: none;
  width: 11px;
  height: 12px;
  margin: 0 0 0 10px;
  background-image: url("/content/themes/eiss/images/catalog-arrow.png");
}

.catalog__text {
  font-size: 1.4em;
  color: #1f1f1f;
}

.catalog__text .button {
  background-color: #fff;
  color: #B62226;
  display: block;
}

.catalog__text .button:hover {
  background-color: #fff;
  text-decoration: underline;
}

.catalogs__list .button {
  text-align: left;
  padding: 12px 0px;
}

.catalogs {
  border-top: 2px solid #D6D6D6;
  border-bottom: 2px solid #D6D6D6;
}

@media only screen and (min-width: 820px) {
  .catalog.catalog--primary {
    padding: 10px 0 0;
  }
}

@media only screen and (min-width: 640px) {
  .catalogs {
    display: block;
    padding-top: 0px;
    margin-bottom: 60px;
    text-align: center;
  }
  .catalogs__text {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 1.4em;
    justify-content: center;
  }
  .catalogs__header {
    display: none;
  }
  .catalogs__list {
    position: relative;
  }
  .catalog:first-child {
    margin-top: 0;
  }
  .catalog__text {
    position: relative;
    align-self: center;
    padding: 0px 10px;
    white-space: nowrap;
    font-size: 26px;
  }
  .catalog__link {
    overflow: visible;
    justify-content: center;
  }
  .catalog__image {
    bottom: -22px;
    left: 0;
    width: 150px;
    height: auto;
    margin-bottom: 0;
  }
  .catalog:last-child {
    border-bottom: none;
  }
}

@media (max-width: 767.98px) {
  .catalogs__header-inner {
    display: none;
  }
}

@media (max-width: 680px) {
  .catalog:last-child {
    border-bottom: none;
  }
  .catalog.catalog--primary {
    text-align: center;
  }
  .catalogs__header {
    padding: 0px;
  }
}

.colorBar__bar {
  background: #B62226;
  border: none;
  width: 100px;
}

.slider-outer {
  margin: 70px 0;
  max-width: 1200px;
  margin: 0 auto 60px;
}

.bx-wrapper .bx-pager {
  text-align: right;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background-color: #fff;
  border: 2px solid #1f1f1f;
  color: rgba(0, 0, 0, 0);
}

.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:hover {
  background-color: #1f1f1f;
}

.bx-wrapper .bx-controls-direction a {
  margin-top: -55px;
  width: 19px;
  height: 33px;
  text-indent: -9999px;
}

.secondary-banner-wrap .bx-wrapper .bx-controls-direction {
  display: block;
}

.bx-wrapper .bx-controls-direction a {
  background: url("/content/themes/eiss/images/universal-nav-arrows.png") no-repeat 0 0;
  width: 35px;
  height: 35px;
}

.bx-wrapper .bx-next,
.bx-wrapper .bx-next:hover {
  background-position: 100% 0;
}

.bx-wrapper .bx-prev,
.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.main-banner-wrap .bx-wrapper .bx-next {
  right: 20px;
  background-position: 100% 0;
}

.main-banner-wrap .bx-wrapper .bx-prev {
  left: 20px;
  background-position: 0 0;
}

.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #000;
}

.dot--is-active,
.dot:hover {
  background-color: #000;
}

.testimonial {
  padding: 40px 25px;
}

.testimonial p {
  line-height: 2;
}

.testimonial h2 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 2em;
}

.text-grid-item {
  background: #F2F0EA;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.about-testimonials-container {
  padding: 40px 20px;
}

.about-header {
  margin: 0 auto .85em;
  color: #000;
  font-size: 26px;
  font-weight: 700;
}

.testimonial-text {
  font-weight: 400;
  font-size: 16px;
}

.testimonial-text span {
  font-weight: 700;
}

.testimonial-item {
  display: none;
}

.testimonial-active {
  display: block;
}

.testimonial-reviewer__name {
  font-style: italic;
  color: #000;
}

.testimonials-container {
  display: block;
  flex-wrap: wrap;
  background: #F2F2F2;
  justify-content: center;
  color: #000;
}

.testimonials-container > div {
  margin: 30px;
  text-align: center;
  font-size: 18px;
}

.about-bx-wrapper .bx-controls-direction a {
  margin-top: -155px;
}

.testimonials .bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  outline: 0;
  text-indent: -9999px;
  z-index: 0;
}

.testimonials .bx-wrapper .bx-next,
.testimonials .bx-wrapper .bx-next:hover {
  background-position: 100% 0;
}

.testimonials .bx-wrapper .bx-prev,
.testimonials .bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.testimonials .testimonial {
  background: #fff;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #fff;
}

.testimonial {
  margin: 20px 0;
}

@media only screen and (min-width: 820px) {
  .testimonials-container > div {
    font-size: 22px;
    max-width: 800px;
    margin: 0 auto;
  }
  .testimonials .bx-wrapper .bx-controls-direction a {
    margin-top: -23%;
  }
  .testimonials-container {
    padding: 50px 0;
  }
}

@media only screen and (min-width: 1000px) {
  .testimonial p {
    line-height: 1.3;
  }
  .testimonial h2 {
    padding-top: 0px;
    font-size: 1.5em;
  }
  .testimonials .bx-wrapper .bx-controls-direction a {
    margin-top: -20%;
  }
  .testimonials .bx-wrapper .bx-next,
  .testimonials .bx-wrapper .bx-next:hover {
    right: -7%;
  }
  .testimonials .bx-wrapper .bx-prev,
  .testimonials .bx-wrapper .bx-prev:hover {
    left: -7%;
  }
}

@media only screen and (min-width: 1400px) {
  .testimonial {
    padding: 25px;
  }
  .testimonial p {
    line-height: 2;
    min-height: 150px;
    max-width: 800px;
  }
  .testimonial h2 {
    padding-top: 0;
    font-size: 2em;
  }
  .testimonials .bx-wrapper .bx-controls-direction a {
    margin-top: -180px;
  }
}

@media (max-width: 819.98px) {
  .testimonials-container > div {
    padding: 30px 10px;
  }
  .about-header {
    font-size: 26px;
  }
  .testimonials .bx-wrapper .bx-controls-direction a {
    margin-top: -200px;
  }
}

@media (max-width: 680px) {
  .secondary-banner-wrap .bx-wrapper .bx-controls-direction a {
    margin-top: -2px;
  }
  .testimonials .bx-wrapper .bx-controls-direction a {
    margin-top: -230px;
  }
}

.about-history {
  margin-top: 60px;
}

.connect-span {
  font-weight: 700;
  color: #000;
}

.history-text {
  color: #000;
}

.why-text {
  color: #000;
}

.connect-p {
  color: #000;
}

.square-p {
  color: #000;
}

.img-text {
  font-weight: 700;
}

@media only screen and (min-width: 681px) {
  .box {
    background-color: #ededed;
  }
}

@media only screen and (min-width: 992px) {
  .main-img {
    position: relative;
  }
  .main-img .img-text {
    font-family: "Albert Sans", sans-serif;
    color: #fff;
    position: absolute;
    top: 60px;
    left: -50px;
    padding: 10px 60px 0px;
    background: #b42025;
    font-size: 35px;
  }
  .page-text {
    padding: 50px 0px;
    color: #b42025;
  }
  .page-text .page-text-title {
    text-align: center;
    text-transform: capitalize;
    font-size: 3em;
    font-weight: 700;
  }
  .about-history img {
    max-height: 400px;
  }
  .about-history .border-left {
    border-left: 5px solid #b42025;
    padding-right: 3px;
  }
  .about-history .border-left .history-title {
    color: #000;
    font-size: 2.3em;
    padding-left: 30px;
    font-weight: 700;
  }
  .about-history .border-left .history-text {
    color: #000;
    font-size: 1.2em;
    line-height: 34px;
    padding: 0 0 0 30px;
  }
  .about-history .border-left .history-text .contract {
    color: #b42025;
    text-decoration: underline;
    cursor: pointer;
  }
  .why-eiss .why-section {
    margin: 50px 0;
  }
  .why-eiss .why-section .why-title {
    font-family: "Albert Sans", sans-serif;
    font-size: 2.3em;
    color: #000;
    margin-top: 40px;
    font-weight: 700;
  }
  .why-eiss .why-section .why-text-container {
    position: relative;
    margin-bottom: 90px;
  }
  .why-eiss .why-section .why-text-container .why-img {
    position: absolute;
    top: 60px;
    right: -835px;
    z-index: 1;
  }
  .why-eiss .why-section .why-text-container .why-img img {
    height: 750px;
  }
  .why-eiss .why-section .why-titleText {
    border: 7px solid #ededed;
    color: #000;
    padding: 20px 242px 80px 80px;
  }
  .why-eiss .why-section .why-titleText .why-text {
    color: #000;
    font-size: 1.2em;
  }
  .box-margin > * + * {
    margin-left: 7px;
  }
  .g-6.md-12.sm-12.box.bx-r > * + * {
    margin-right: 7px;
  }
  .box {
    background-color: #ededed;
  }
  .bg-modal,
  .bg-modal2,
  .bg-modal3 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: 1000;
  }
  .modal-content {
    height: 700px;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    position: relative;
  }
  .modal-content2 {
    height: 600px;
    background: #fff;
    border-radius: 20px;
    text-align: center;
    margin-top: 80px;
    padding: 20px;
    position: relative;
  }
  .modal-content3 {
    width: 500px;
    height: 250px;
    background: #fff;
    border-radius: 20px;
    text-align: center;
    margin-top: 125px;
    padding: 20px;
    position: relative;
  }
  .modal-bg img {
    max-height: 650px;
    width: 100%;
  }
  .modal-bg2 img {
    max-height: 580px;
  }
  .modal-bg3 {
    height: 230px;
  }
  .close {
    display: none;
  }
  .close,
  .close2,
  .close3 {
    position: absolute;
    top: 0;
    right: 14px;
    font-size: 2.6em;
    font-weight: 400;
    color: #b7b7b7;
    transform: rotate(45deg);
    cursor: pointer;
  }
  .close2 {
    position: absolute;
    top: -15px;
    right: 14px;
    font-size: 2.6em;
    font-weight: 400;
    color: #b7b7b7;
    transform: rotate(45deg);
    cursor: pointer;
  }
  .contract-text {
    padding: 5px 5px;
    font-size: 1.2em;
    font-weight: 400;
    text-align: left;
  }
  .connect-container {
    margin-bottom: 90px;
  }
  .connect-container .connect {
    padding: 50px 0px;
    border: 7px solid #ededed;
  }
  .connect-container .connect .connect-text {
    text-align: center;
  }
  .connect-container .connect .connect-text .connect-title {
    font-weight: 700;
    font-size: 3em;
    text-transform: capitalize;
    color: #b42025;
  }
  .connect-container .connect .connect-text .connect-p {
    color: #000;
    font-size: 1.2em;
  }
  .connect-container .connect .all-squares {
    margin-top: 70px;
  }
  .connect-container .connect .all-squares .square-container {
    padding: 0px 200px;
    font-weight: 400;
  }
  .connect-container .connect .all-squares .square-container .square-img {
    text-align: -webkit-center;
  }
  .connect-container .connect .all-squares .square-container .square-img img {
    width: 70px;
  }
  .connect-container .connect .all-squares .square-container .square-text {
    color: #000;
    margin-top: 15px;
  }
  .connect-container .connect .all-squares .square-container .square-text .square-p {
    text-align: center;
  }
  .boxes .box-btn .button.box-button {
    font-size: 1.3em;
    font-weight: 400;
    width: 75%;
    text-transform: uppercase;
  }
}

.boxes {
  margin-bottom: 60px;
}

.boxes .box-btn {
  text-align: center;
  margin-top: 10px;
}

@media (min-width: 1024px) and (max-width: 1198px) {
  .why-eiss .why-section .why-titleText {
    padding: 20px 242px 0px 20px;
  }
}

@media only screen and (max-width: 991.98px) {
  .main-img {
    position: relative;
  }
  .main-img .img-text {
    font-family: "Albert Sans", sans-serif;
    color: #fff;
    position: absolute;
    top: 10px;
    left: 0px;
    padding: 10px 10px 0px;
    background: #b42025;
    font-size: 1.2em;
  }
  .page-text {
    padding: 50px 0px;
    font-style: italic;
    color: #b42025;
  }
  .page-text .page-text-title {
    text-align: center;
    text-transform: capitalize;
    font-size: 2em;
  }
  .about-history {
    margin-top: 0px;
  }
  .about-history img {
    margin-left: 0px;
  }
  .about-history .border-left {
    border-left: 0px solid #b42025;
  }
  .about-history .border-left .history-title {
    color: #000;
    font-size: 2.3em;
    padding-left: 0px;
    margin-top: 20px;
    margin-bottom: 5px;
    padding: 0 10px;
  }
  .about-history .border-left .history-text {
    color: #000;
    font-size: 1em;
    line-height: 23px;
    padding: 0;
    padding: 0 10px;
  }
  .about-history .border-left .history-text .contract {
    color: #b42025;
    text-decoration: underline;
    cursor: pointer;
  }
  .why-eiss .why-section .why-title {
    margin-top: 50px;
    margin-bottom: 5px;
    font-family: "Albert Sans", sans-serif;
    font-size: 2.3em;
    color: #000;
  }
  .why-eiss .why-section .why-text-container .why-img {
    margin-bottom: 40px;
  }
  .why-eiss .why-section .why-titleText {
    border: 0px solid #ededed;
  }
  .why-eiss .why-section .why-titleText .why-text {
    font-size: 1em;
  }
  .bg-modal,
  .bg-modal2,
  .bg-modal3 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: 1000;
  }
  .modal-content {
    background: #fff;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    position: relative;
  }
  .modal-content2 {
    background: #fff;
    border-radius: 20px;
    text-align: center;
    margin-top: 80px;
    padding: 20px;
    position: relative;
  }
  .modal-content3 {
    width: 500px;
    height: 250px;
    background: #fff;
    border-radius: 20px;
    text-align: center;
    margin-top: 125px;
    padding: 20px;
    position: relative;
  }
  .modal-bg img {
    max-height: 650px;
    width: 100%;
  }
  .modal-bg2 img {
    max-height: 580px;
  }
  .modal-bg3 {
    height: 230px;
  }
  .close,
  .close2,
  .close3 {
    position: absolute;
    top: 0;
    right: 14px;
    font-size: 2.6em;
    font-weight: 400;
    color: #b7b7b7;
    transform: rotate(45deg);
    cursor: pointer;
  }
  .bg-modal,
  .bg-modal2,
  .bg-modal3 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: 1000;
  }
  .modal-content {
    background: #fff;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    position: relative;
  }
  .modal-content2 {
    background: #fff;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    position: relative;
  }
  .modal-content3 {
    width: 500px;
    height: 220px;
    background: #fff;
    border-radius: 20px;
    text-align: center;
    margin-top: 125px;
    padding: 20px;
    position: relative;
  }
  .modal-bg img {
    width: 100%;
  }
  .modal-bg3 {
    height: 230px;
  }
  .close,
  .close2,
  .close3 {
    padding-top: 10px;
    position: absolute;
    top: 0;
    right: 14px;
    font-size: 2.6em;
    font-weight: 400;
    color: #b7b7b7;
    transform: rotate(45deg);
    cursor: pointer;
  }
  .contract-text {
    padding: 20px 10px;
    font-size: 1.2em;
    font-weight: 400;
    text-align: left;
  }
  .connect-container {
    margin-bottom: 90px;
  }
  .connect-container .connect {
    border: 7px solid #ededed;
  }
  .connect-container .connect .connect-text {
    text-align: center;
  }
  .connect-container .connect .connect-text .connect-title {
    font-weight: 400;
    font-size: 2em;
    text-transform: capitalize;
    font-style: italic;
    color: #b42025;
  }
  .connect-container .connect .connect-text .connect-p {
    font-size: 0.9em;
    font-weight: 400;
  }
  .connect-container .connect .all-squares {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .connect-container .connect .all-squares .square-container {
    padding: 0px 0px;
    font-weight: 400;
  }
  .connect-container .connect .all-squares .square-container .square-img {
    text-align: -webkit-center;
  }
  .connect-container .connect .all-squares .square-container .square-img img {
    width: 70px;
  }
  .connect-container .connect .all-squares .square-container .square-text {
    margin-top: 15px;
  }
  .connect-container .connect .all-squares .square-container .square-text .square-p {
    text-align: center;
  }
}

@media (min-width: 360px) and (max-width: 991px) {
  .page-content {
    line-height: 1.4em;
  }
  .why-eiss .why-section .why-titleText {
    margin-bottom: 30px;
    border: 5px solid #ededed;
    padding: 0 20px;
  }
  .connect-container {
    margin-bottom: 90px;
  }
  .connect-container .connect {
    border: 7px solid #ededed;
  }
  .connect-container .connect .connect-text {
    text-align: center;
  }
  .connect-container .connect .connect-text .connect-title {
    font-weight: 400;
    font-size: 3em;
    text-transform: capitalize;
    font-style: italic;
    color: #b42025;
  }
  .connect-container .connect .connect-text .connect-p {
    font-size: 0.9em;
    font-weight: 400;
  }
  .connect-container .connect .all-squares {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .connect-container .connect .all-squares .square-container {
    padding: 0px 0px;
    font-weight: 400;
  }
  .connect-container .connect .all-squares .square-container .square-img {
    text-align: -webkit-center;
  }
  .connect-container .connect .all-squares .square-container .square-img img {
    width: 70px;
  }
  .connect-container .connect .all-squares .square-container .square-text {
    margin-top: 15px;
  }
  .connect-container .connect .all-squares .square-container .square-text .square-p {
    text-align: center;
  }
}

@media (min-width: 320px) and (max-width: 568px) {
  .why-eiss .why-section .why-title {
    margin-top: 0px !important;
  }
  .why-eiss .why-section .why-text-container .why-img {
    top: 110px;
  }
}

@media (min-width: 320px) and (max-width: 568px) {
  .modal-content3 {
    height: 300px;
    padding: 10px;
  }
  .close3 {
    top: -13px;
  }
}

@media (min-width: 375px) and (max-width: 812px) {
  .modal-content3 {
    height: 225px;
  }
  .close3 {
    padding-top: 20px;
  }
}

@media (min-width: 0px) and (max-width: 568px) {
  .about-history {
    margin-top: 0px;
  }
  .about-history img {
    margin-left: 0px;
  }
  .about-history .border-left {
    border-left: 0px solid #b42025;
  }
  .about-history .border-left .history-title {
    color: #000;
    font-size: 2.3em;
    padding-left: 0px;
    padding: 0 10px;
    margin-bottom: 5px;
  }
  .about-history .border-left .history-text {
    color: #000;
    font-size: 1em;
    line-height: 18px;
    padding: 0 10px;
  }
  .about-history .border-left .history-text .contract {
    color: #b42025;
    text-decoration: underline;
    cursor: pointer;
  }
  .why-eiss .why-section .why-title {
    margin-top: 50px;
    margin-bottom: 5px;
    font-family: "Albert Sans", sans-serif;
    font-size: 2.3em;
    color: #000;
    padding: 0 10px;
  }
  .why-eiss .why-section .why-text-container .why-img {
    margin-bottom: 40px;
  }
  .why-eiss .why-section .why-titleText {
    border: 0px solid #ededed;
    padding: 0;
  }
  .why-eiss .why-section .why-titleText .why-text {
    font-size: 1em;
    line-height: 1.4em;
    padding: 0 10px;
  }
  .connect-container {
    margin-bottom: 90px;
  }
  .connect-container .connect {
    border: 7px solid #ededed;
  }
  .connect-container .connect .connect-text {
    text-align: center;
  }
  .connect-container .connect .connect-text .connect-title {
    font-weight: 400;
    font-size: 2em;
    text-transform: capitalize;
    font-style: italic;
    color: #b42025;
  }
  .connect-container .connect .connect-text .connect-p {
    font-size: 0.8em;
    font-weight: 400;
  }
  .connect-container .connect .all-squares {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .connect-container .connect .all-squares .square-container {
    padding: 0px 0px;
    font-weight: 400;
  }
  .connect-container .connect .all-squares .square-container .square-img {
    text-align: -webkit-center;
  }
  .connect-container .connect .all-squares .square-container .square-img img {
    width: 70px;
  }
  .connect-container .connect .all-squares .square-container .square-text {
    margin-top: 15px;
  }
  .connect-container .connect .all-squares .square-container .square-text .square-p {
    text-align: center;
  }
  .modal-content3 {
    width: 500px;
  }
}

.home-about {
  margin: 60px 0;
  font-weight: 100;
  background: #000;
}

.home-about__inner {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
}

@media only screen and (min-width: 960px) {
  .home-about__inner {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.home-about__graphic {
  order: 2;
  padding: 0 10px;
}

@media only screen and (min-width: 960px) {
  .home-about__graphic {
    align-self: flex-end;
    width: 50%;
  }
}

.home-about__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0 20px;
}

.home-about__text {
  order: 1;
  padding: 20px;
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
}

@media only screen and (min-width: 960px) {
  .home-about__text {
    order: 3;
    width: 50%;
    font-size: 16px;
  }
}

.home-about__text p {
  margin: 0;
  padding: 0;
}

.home-about__header {
  margin: 0 0 10px;
  padding: 0;
  color: #1e7185;
  font-size: 16px;
}

@media only screen and (min-width: 960px) {
  .home-about__header {
    font-size: 22px;
  }
}

.home-services {
  display: flex;
  justify-content: center;
  gap: 15px;
  max-width: 1200px;
  margin: 0 auto;
}

.home-services__top, .home-services__bottom {
  padding: 0;
}

.home-services__boxes {
  background-color: #ededed;
  padding: 40px;
  height: 100%;
}

.home-services__boxes p {
  font-size: 1.3em;
}

.home-services__boxes span {
  color: #B62226;
  font-weight: 700;
}

.home-services__boxes .shop-online__number {
  color: #B62226;
  font-size: 16px;
  font-weight: 700;
}

.home-services__text {
  line-height: 28px;
}

.home-services__btn {
  padding-top: 20px;
}

@media only screen and (max-width: 680px) {
  .home-services {
    display: block;
  }
  .home-services__top {
    margin-bottom: 40px;
    padding: 0 10px;
  }
  .home-services__bottom {
    padding: 0 10px;
  }
  .boxes .box-btn {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .connect-container .connect .connect-text {
    padding-top: 100px;
  }
}

@media (max-width: 991.98px) {
  .connect-container .connect .connect-text {
    padding-top: 50px;
  }
}

.home-steps {
  max-width: 1000px;
  margin: 30px 10px;
  padding: 15px 0;
  font-weight: 100;
  border: 1px solid #B62226;
}

@media only screen and (min-width: 680px) {
  .home-steps {
    margin: 60px;
  }
}

@media only screen and (min-width: 1120px) {
  .home-steps {
    margin: 60px auto;
  }
}

.home-steps__inner {
  display: flex;
  flex-direction: column;
  margin: -16px 20px;
  background: #fff;
}

@media only screen and (min-width: 680px) {
  .home-steps__inner {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.home-steps__header {
  position: relative;
  align-self: center;
  padding: 15px 0;
}

@media only screen and (min-width: 680px) {
  .home-steps__header {
    width: 50%;
    padding: 15px 30px;
    border-right: 1px solid #000;
  }
  .home-steps__header::after, .home-steps__header::before {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    width: 0;
    height: 0;
    border: solid transparent;
    pointer-events: none;
  }
  .home-steps__header::after {
    margin-top: -12px;
    border-color: transparent;
    border-left-color: #fff;
    border-width: 12px;
  }
  .home-steps__header::before {
    margin-top: -13px;
    border-color: transparent;
    border-left-color: #000;
    border-width: 13px;
  }
}

.home-steps__title {
  margin: 0;
  padding: 0;
  color: #B62226;
  font-size: 36px;
  font-weight: 100;
  line-height: 1.25;
  text-align: center;
  text-transform: uppercase;
}

@media only screen and (min-width: 680px) {
  .home-steps__title {
    font-size: 52px;
  }
}

.home-steps__text {
  padding: 15px 0;
  font-size: 16px;
  line-height: 1.4;
}

@media only screen and (min-width: 680px) {
  .home-steps__text {
    width: 50%;
    padding: 30px 30px 30px 35px;
    font-size: 24px;
  }
}

.home-steps__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.home-steps__item {
  margin-bottom: 20px;
}

.home-steps__item:last-child {
  margin-bottom: 0;
}

.home-steps__item strong {
  font-weight: bold;
}

/*@import "styles/home-tips";*/
.deal-sku__link {
  color: #0e758e;
}

.price__label--highlight {
  background-color: #B62226;
}

/*@import "styles/calendar";*/
.home-contact {
  max-width: 1000px;
  margin: 0 auto;
  color: #6d6e70;
}

.home-contact__inner {
  overflow: hidden;
  text-align: center;
}

.home-contact__title {
  margin: 0 0 40px;
  padding-top: 60px;
  font-weight: 100;
  font-size: 2.5em;
  border-top: 1px solid #000;
  text-transform: capitalize;
  max-width: 1200px;
}

.home-contact__section {
  position: relative;
  float: left;
  width: 33.3333333%;
  line-height: 20px;
}

.home-contact__content {
  font-size: 16px;
  transform: translateY(-50%);
  color: #1F1F1F;
}

.home-contact__content a {
  color: #1F1F1F;
}

.i--pin,
.i--phone,
.i--laptop {
  display: none;
  display: block;
  height: 71px;
  background: url("/content/themes/eiss/images/home-sprite.png") no-repeat 0 0;
}

.i--pin {
  display: none;
  width: 47px;
}

.i--phone {
  display: none;
  width: 55px;
  background-position: -47px 0;
}

.i--laptop {
  display: none;
  width: 92px;
  background-position: -102px 0;
}

@media only screen and (max-width: 820px) {
  .i--pin,
  .i--phone,
  .i--laptop {
    height: 50px;
    background-size: 137px 50px;
  }
  .i--pin {
    width: 33px;
  }
  .i--phone {
    width: 39px;
    background-position: -33px 0;
  }
  .i--laptop {
    width: 65px;
    background-position: -72px 0;
  }
  .home-contact__content {
    left: 75px;
  }
}

@media only screen and (max-width: 700px) {
  .home-contact {
    margin: 0;
  }
  .home-contact__content .list--plain {
    padding-bottom: 10px;
  }
  .home-contact__section {
    width: 50%;
    margin: 0 0 15px;
  }
  .home-content__section:nth-child(odd) {
    clear: both;
  }
}

@media only screen and (max-width: 500px) {
  .home-contact__section {
    float: none;
    width: auto;
  }
}

.video-thumb__link {
  color: #991d23;
}

.footer-inner {
  max-width: 1200px;
  margin: 0 auto;
}

.site-footer {
  max-width: 2000px;
  background-color: #B62226;
  color: #fff;
  padding: 90px 0 50px 0;
}

.site-footer h2 {
  margin: 0;
}

.site-footer .g-6 {
  padding: 0 80px;
}

.site-footer a {
  color: #fff;
}

.right-border {
  border-right: 2px solid #fff;
}

.footer-header {
  font-family: "Albert Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  padding: 12px 0 5px;
}

.site-footer__header {
  font-family: "Albert Sans", sans-serif;
  font-weight: 700;
  border-bottom: none;
  font-size: 20px;
  text-transform: capitalize;
}

.site-footer__panel p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 2px;
}

.site-footer__item a {
  text-decoration: none;
  font-size: 16px;
}

.list--plain .social__item {
  padding: 0px;
}

.site-footer__copyright {
  text-transform: uppercase;
  color: #1f1f1f;
}

.site-footer__copyright small {
  font-size: 13px;
  color: #fff;
}

@media only screen and (max-width: 575.98px) {
  .social-container {
    display: flex;
    justify-content: center;
  }
  .site-footer {
    padding: 20px 0 20px 0;
  }
  .site-footer .g-6 {
    padding: 0;
  }
  .footer-header {
    font-size: 2em;
  }
  .site-footer__panel {
    text-align: center;
  }
  .site-footer__panel p {
    font-size: 1em;
  }
  .right-border {
    border-right: none;
  }
}

.secondary-banner-wrap {
  max-width: 1200px;
  margin: 60px auto;
}

.secondary-banner-wrap .banner-feature {
  margin-top: 20px;
}

@media only screen and (min-width: 681px) {
  .secondary-banner-wrap .banner-feature {
    margin-top: 0px;
  }
}

.myaccount-nav__link {
  background-color: #B62226;
}

.myaccount-nav__link--selected, .myaccount-nav__link:hover {
  background-color: #770c11;
}

@media only screen and (min-width: 1000px) {
  .dashboard-buckets,
  .dashboard,
  .recently-viewed.recommended {
    max-width: 1000px;
    margin: auto;
  }
}

.home-article .article--animation:after,
.slidewrap a:after {
  content: "SHOP NOW";
  display: inline-block;
  position: relative;
  top: 0;
  left: 50%;
  opacity: 0;
  background: #B62226;
  color: #fff;
  padding: 12px 50px;
  transition: top 0.5s, opacity 0.5s;
  border-radius: 30px;
  font-family: basic-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-size: 13px;
  transform: translateX(-50%);
}

.slidewrap a .slider-caption {
  opacity: 1;
  transition: opacity 0.5s;
  font-weight: 400;
}

.slidewrap a:hover {
  text-decoration: none;
}

.slidewrap a:hover:after {
  top: -13px;
  opacity: 1;
}

.slidewrap a:hover .slider-caption {
  opacity: 0 !important;
}

.home-article a:after {
  content: none;
}

.home-article .home-article__link {
  position: relative;
}

.home-article .article--animation:after {
  display: none;
  content: "READ NOW";
  position: absolute;
  top: auto;
  left: 0;
  bottom: -13px;
  right: 0;
  padding: 10px 20px;
  transform: translateY(100%);
  transition: bottom 0.5s, opacity 0.5s;
}

.banner--slider .slidewrap a:after {
  content: "SHOP NOW";
}

.banner--slider .slidewrap a:hover:after {
  top: -34px;
  opacity: 1;
}

.bx-wrapper .bx-controls-direction a.disabled {
  display: block;
  z-index: 0;
}

.slider--new .bx-wrapper .bx-next {
  background-position: 100% 0;
}

.slider--bestseller .bx-wrapper .bx-prev, .slider--new .bx-wrapper .bx-prev {
  left: 0px;
  z-index: 0;
}

.slider--bestseller .bx-wrapper .bx-next, .slider--new .bx-wrapper .bx-next {
  right: 0px;
  z-index: 0;
  background-position: 100% 0;
}

/*@media (min-width: 680px) {
  .slidewrap img {
    max-width: 230px;
  }
}*/
@media (max-width: 480px) {
  .banner--slider .slidewrap a:after,
  .slidewrap a:after {
    left: 100px;
  }
  .slidewrap a:hover:after {
    left: 100px;
    padding: 10px 20px;
  }
  .banner--slider .slidewrap a:hover:after {
    top: -23px;
  }
  .home-article:hover .article--animation:after {
    left: 0;
  }
}

@media (max-width: 380px) {
  .slidewrap a:after {
    padding: 10px;
    left: 28px;
    font-size: 14px;
  }
  .slidewrap a:hover:after {
    left: 28px;
    padding: 10px;
    font-size: 14px;
  }
  .banner--slider .slidewrap a:after {
    padding: 10px;
    left: 40px;
    font-size: 14px;
  }
  .banner--slider .slidewrap a:hover:after {
    top: -20px;
    font-size: 14px;
  }
}

.samples__toggle {
  background: none;
  background-color: #b42025;
  color: #fff;
}

.samples__toggle--svg-container {
  background-color: #971b1f;
}

img {
  max-width: 100%;
  height: auto;
}

.main-content {
  max-width: 1200px;
  margin: 0 auto;
}

.main-content__inner > div {
  max-width: 1260px;
  margin: 0 auto;
  padding-bottom: 0px;
}

.main-content {
  max-width: none;
}

.main-content__inner > div {
  max-width: 1000px;
  margin: 0 auto;
}

.has--pencil .pencil,
.has--pencil .pencil-multi,
.pencil-banner {
  max-width: none;
}

.page--default .main-content__inner > div,
.page--landing-default .main-content__inner > div,
.page--explore-deals-default .main-content__inner > div {
  max-width: none;
}

.landing__header {
  display: none;
}

.page-sections,
.page-header {
  display: none;
}

.home-article__title {
  color: #1f1f1f;
  text-align: center;
}

.home-articles__img {
  padding: 0 20px !important;
}

.home-article__teaser {
  display: none;
}

.home-article__title {
  color: #991d23;
  font-weight: 800;
  color: #1f1f1f;
  padding: 10px 0;
  text-align: center;
  text-decoration: none !important;
}

.home-article__link--read {
  color: #fff !important;
  background-color: #B62226;
  border-radius: 25px;
  padding: 5px 20px;
  font-weight: 400;
  text-align: center;
}

.home-article__link--read:after {
  display: none;
}

div.fs-wrapper div.fs-has-links::after {
  color: #fff;
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 25px;
  padding: 10px 12px;
  border: 1px solid #fff;
}

.fs-wrapper div.fs-text-container:hover {
  background-color: #991d2391 !important;
}

.bar {
  color: #1f1f1f;
  font-size: 26px;
  font-weight: 100;
  text-align: center;
  background-color: transparent;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.bar span {
  font-family: "Albert Sans", sans-serif;
  font-weight: 700;
}

.instagram-widget .bar {
  margin-top: 80px;
}

.inner-body-container {
  max-width: 1000px;
  margin: 0 auto;
}

.step {
  margin-top: 0;
}

.salon-text {
  line-height: 28px;
  font-size: 1.5em;
}

.slider-caption {
  color: #1f1f1f !important;
  font-size: 16px;
}

.home-contact {
  margin: 0 auto;
}

.home-contact .bar {
  padding-bottom: 40px;
}

.home-contact__title {
  font-family: "Albert Sans", sans-serif;
  font-weight: 700;
  font-size: 3em;
  border: none;
}

.login-section {
  width: 50%;
}

.fs-fa-instagram:before {
  display: none;
}

.fs-has-links::after {
  content: "SHOP NOW" !important;
}

.instagram-widget {
  max-width: 1200px;
  margin: 0 auto;
}

.post__title a,
.post__link a {
  color: #1f1f1f;
}

.txt-center {
  margin: 20px 0;
}

@media only screen and (min-width: 680px) {
  .address-wrap .account-panel {
    width: calc(49% - 10px);
  }
  .txt-center {
    margin: 20px 0 100px;
  }
}

@media only screen and (min-width: 820px) {
  .slider-caption {
    max-width: 250px;
    margin: 8px 100px 0;
  }
}

@media only screen and (max-width: 680px) {
  .inner-box-container:first-child {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 575.98px) {
  .box-margin {
    padding-top: 20px;
  }
  .home-contact__inner {
    text-align: center;
  }
  .login-section {
    width: 100%;
  }
  .blog-scroller {
    border-bottom: 1px solid #000;
  }
  .salon-text {
    line-height: 20px;
    font-size: 16px;
  }
}
