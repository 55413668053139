.home-steps {
	max-width: 1000px;
	margin: 30px 10px;
	padding: 15px 0;
	font-weight: 100;
	border: 1px solid $eiss-primary;

	@media only screen and (min-width: 680px) {
		margin: 60px;
	}

	@media only screen and (min-width: 1120px) {
		margin: 60px auto;
	}
}

.home-steps__inner {
	display: flex;
	flex-direction: column;
	margin: -16px 20px;
	background: #fff;
	
	@media only screen and (min-width: 680px) {
		flex-direction: row;
		flex-wrap: nowrap;
		
	}
}

.home-steps__header {
	position: relative;
	align-self: center;
	padding: 15px 0;
	
	@media only screen and (min-width: 680px) {
		width: 50%;
		padding: 15px 30px;
		border-right: 1px solid #000;

		&::after, &::before {
			content: '';
			position: absolute;
			left: 100%;
			top: 50%;
			width: 0;
			height: 0;
			border: solid transparent;
			pointer-events: none;
		}
	
		&::after {
			margin-top: -12px;
			border-color: transparent;
			border-left-color: #fff;
			border-width: 12px;
		}
	
		&::before {
			margin-top: -13px;
			border-color: transparent;
			border-left-color: #000;
			border-width: 13px;
		}
	}
}

.home-steps__title {
	margin: 0;
	padding: 0;
	color: $eiss-primary;
	font-size: 36px;
	font-weight: 100;
	line-height: 1.25;
	text-align: center;
	text-transform: uppercase;

	@media only screen and (min-width: 680px) {
		font-size: 52px;
	}
}

.home-steps__text {
	padding: 15px 0;
	font-size: 16px;
	line-height: 1.4;
	
	@media only screen and (min-width: 680px) {
		width: 50%;
		padding: 30px 30px 30px 35px;
		font-size: 24px;
	}
}

.home-steps__list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.home-steps__item {
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}

	strong {
		font-weight: bold;
	}
}
