﻿.myaccount-nav__link {
    background-color: $eiss-primary;

    &--selected,
    &:hover {
        background-color: $eiss-primary-dark;
    }
}

@media only screen and (min-width: 1000px) {
    .dashboard-buckets,
    .dashboard,
    .recently-viewed.recommended {
        max-width: 1000px;
        margin: auto;
    }
}