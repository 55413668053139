// colors
$eiss-primary: #B62226;
$eiss-primary-dark: #770c11;
$eiss-secondary: #1e7185;
$link-blue: #005ca0;
$main-font: #1f1f1f;
$background-light: #f2f2f2;

//fonts
@import "styles/fonts";

$font-thin: "Brandon Grotesque W01 Thin", sans-serif;
$font-bold: "Brandon Grotesque W01 Bold", sans-serif;
$font-titles: "Albert Sans", sans-serif;
$font-all: "Albert Sans", sans-serif;

body {
  font-family: $font-all;
  color: $main-font;
}

h2 {
  font-size: 26px
}

.main-banner-wrap .bx-controls-direction {
  display: block;
  
}

@import "styles/buttons";
@import "styles/social";
@import "styles/header-top";
@import "styles/site-nav";
@import "styles/home-catalogs";
@import "styles/home-sliders";
@import "styles/home-about";
@import "styles/home-steps";
/*@import "styles/home-tips";*/
@import "styles/products";
/*@import "styles/calendar";*/
@import "styles/home-contact";
@import "styles/video-gallery";
@import "styles/site-footer";
@import "styles/home-banner";
@import "styles/dashboard";
@import "styles/slideranimation";
@import "styles/sample-toggles";

img {
    max-width: 100%;
    height: auto;
}

.main-content {
    max-width: 1200px;
    margin: 0 auto;
}

.main-content__inner > div {
    max-width: 1260px;
    margin: 0 auto;
    padding-bottom: 0px;
}

// Full width pencil banner
.main-content {
    max-width: none;
}

.main-content__inner > div {
    max-width: 1000px;
    margin: 0 auto;
}

.has--pencil .pencil,
.has--pencil .pencil-multi,
.pencil-banner {
    max-width: none;
}

.page--default,
.page--landing-default,
.page--explore-deals-default {
    .main-content__inner > div {
        max-width: none;
    }
}

.landing__header {
  display: none;
}

.page-sections,
.page-header {
    display: none;
}

// home articles
.home-article__title {
  color: $main-font;
  text-align: center;
}

.home-articles__img {
  padding: 0 20px !important;
}

.home-article__teaser {
  display: none;
}

.home-article__title {
  color: #991d23;
  font-weight: 800;
  color: $main-font;
  padding: 10px 0;
  text-align: center;
  text-decoration: none !important;
}

.home-article__link--read {
  color: #fff !important;
  background-color: $eiss-primary;
  border-radius: 25px;
  padding: 5px 20px;
  font-weight: 400;
  text-align: center;
}

.home-article__link--read:after {
  display: none;
}

div.fs-wrapper div.fs-has-links::after {
  color: #fff;
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 25px;
  padding: 10px 12px;
  border: 1px solid #fff;
}

.fs-wrapper div.fs-text-container:hover {
  background-color: #991d2391 !important;
}

.bar {
    color: $main-font;
    font-size: 26px;
    font-weight: 100;
    text-align: center;
    background-color: transparent;
    overflow: hidden;
    padding: 0px;
    margin-bottom: 30px;
    margin-top: 30px;

    span {
        font-family: $font-titles;
        font-weight: 700;
    }
}

.instagram-widget{
  .bar{
    margin-top: 80px;
  }
}

// EISS REDESIGN HOME PAGE

.inner-body-container {
  max-width: 1000px;
  margin: 0 auto;
}

.step {
  margin-top: 0;
}

.salon-text {
  line-height: 28px;
  font-size: 1.5em;
}

.slider-caption {
  color: $main-font !important;
  font-size: 16px;
}

.home-contact {
    margin: 0 auto;

    .bar{
      padding-bottom: 40px;
    }
}

.home-contact__title {
    font-family: $font-titles;
    font-weight: 700;
    font-size: 3em;
    border: none;
}

// EISS LOGIN
.login-section {
    width: 50%;
}

// Instagram
.fs-fa-instagram:before {
  display: none;
}

.fs-has-links::after {
  content: "SHOP NOW" !important;
}

.instagram-widget {
  max-width: 1200px;
  margin: 0 auto;
}

.post__title,
.post__link {
    a {
        color: $main-font;
    }
}

.txt-center{
  margin: 20px 0;
}

@media only screen and (min-width: 680px) {
  .address-wrap .account-panel {
    width: calc(49% - 10px);
  }

  .txt-center{
    margin: 20px 0 100px;
  }
}

@media only screen and (min-width: 820px) {
  .slider-caption {
    max-width: 250px;
    margin: 8px 100px 0;
  }
}

@media only screen and (max-width: 680px) {
    .inner-box-container:first-child {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 575.98px) {    
    .box-margin {
        padding-top: 20px;
    }    

    .home-contact__inner {
        text-align: center;
    }

    .login-section {
        width: 100%;
    }

    .blog-scroller {
        border-bottom: 1px solid #000;
    }

    .salon-text {
        line-height: 20px;
        font-size: 16px;
    }
}
