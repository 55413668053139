.footer-inner {
    max-width: 1200px;
    margin: 0 auto;
}

.site-footer {
    max-width: 2000px;
    background-color: $eiss-primary;
    color: #fff;
    padding: 90px 0 50px 0;

    h2 {
        margin: 0;
    }

    .g-6 {
        padding: 0 80px;
    }

    a {
        color: #fff;
    }
}

.right-border {
    border-right: 2px solid #fff;
}

.footer-header {
    font-family: $font-titles;
    font-weight: 700;
    font-size: 20px;
    padding: 12px 0 5px;
}

.site-footer__header {
  font-family: $font-titles;
  font-weight: 700;
  border-bottom: none;
  font-size: 20px;
  text-transform: capitalize;
}

.site-footer__panel p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 2px;
}

.site-footer__item {
    a {
        text-decoration: none;        
        font-size: 16px;
    }
}

.list--plain .social__item {
    padding: 0px;
}

.site-footer__copyright {
  text-transform: uppercase;
  color: $main-font;

  small {
    font-size: 13px;
    color: #fff;
  }
}

@media only screen and (max-width: 575.98px) {
    .social-container {
        display: flex;
        justify-content: center;
    }

    .site-footer {
        padding: 20px 0 20px 0;

        .g-6 {
            padding: 0;
        }
    }

    .footer-header{
        font-size: 2em;
    }

    .site-footer__panel {
        text-align: center;

        p {
            font-size: 1em;
        }
    }

    .right-border {
        border-right: none;
    }
}
