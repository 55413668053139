/* albert-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/content/themes/eiss/fonts/albert-sans-v1-latin-regular.woff2') format('woff2'),
       url('/content/themes/eiss/fonts/albert-sans-v1-latin-regular.woff') format('woff')
}

/* albert-sans-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/content/themes/eiss/fonts/albert-sans-v1-latin-700.woff2') format('woff2'), 
       url('/content/themes/eiss/fonts/albert-sans-v1-latin-700.woff') format('woff')
}
