//--------------------- Begin new mobile header ----------------//
.top-header {
    background-color: $background-light;
}

.top-header__inner {
    display: grid;
    max-width: 1200px;
    margin: 0 auto;
}

.header-top__note {
    display: none;
}

.utility-bar {
    grid-row: 1;
    grid-column: 2;
    background-color: unset;
}

.header-top__note--mobile {
    grid-row: 3;
    grid-column: 1;
    padding: 5px 0 5px 20px;
    text-align: left;
    font-weight: bold;
    font-size: 13px;

    .is--logged-in & {
        padding-top: 0px;
    }
}

.header-top__countdown {
    grid-row: 3;
    grid-column: 2;
    width: 100%;    
    text-align: right;    
    padding: 0 20px 0 0;
}

.utility-bar__inner {
    grid-template-columns: 1fr auto auto;
    align-items: center;
    max-width: 1200px;
    padding: 0 12px;

    a {
        color: $main-font;        
    }
}

.login-links {
    grid-column: 2;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 700;
}

.account-header {
    grid-column: 2;
    background-color: unset;
    color: #000;
}

.header-cart {
    grid-column: 3;
}

.login-links,
.header-cart {
    margin-right: 8px;
}

.account-header__icon {
    grid-column: 2;
    grid-row: 1;
    display: block;
    justify-self: right;

    .icon-person .icon-circle {
        stroke: #000;
        stroke-width: 1;
    }

    .icon-person .icon-body,
    .icon-person .icon-head,
    .account-header__toggle {
        fill: #000;
    }
}

.account-header__value {
    color: #fff;
}

.account-header__toggle {
    display: block;
    justify-self: left;
}

.header-cart__icon svg,
.account-header__toggle svg {
    fill: #000;
}

.header-cart__value {
    color: #fff;
}

.account-header__nav {
    background-color: $background-light;
    color: $main-font;
    box-shadow: 3px 3px 8px rgba(0,0,0,.24);
}

//------- Beauty connection NAV----------------
.header-top-sites-container {
    grid-row: 1;
    grid-column: 1;
}

.header-top-site-items {   
    display: flex;
    justify-content: start;
    align-items: center;    
}

.header-top-site-item {
    margin: 0 20px;
    font-size: 1.4em;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        color: $main-font;
    }
}

.parent-site {
    font-family: $font-titles;
    text-transform: lowercase;
    font-weight: 700;
    font-size: 23px;
}

.beautyconnection-site {
    background: #2a5765;
    padding: 8px 16px;
    margin: 0;
    font-size: 17px;

    a {
        color: #fff;
    }

    span {
        font-family: $font-bold;
        font-weight: bold;
    }
}

.header-site-link__sub {
    font-style: italic;
    font-size: 12px;
    font-weight: normal;
    margin: 0px;
    margin-top: -5px;
    letter-spacing: 0;
}

.header-top-site__text {
    display: none;
}

.site-logo--mobile {
    display: none;
}

.header-banner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1200px;
    padding: 20px 0;
}

.site-logo {
    padding: 0;
    margin: 0 auto;
    max-width: 120px;
    grid-column: 2;
}

.header-search {
    grid-column: 3;    

    .header-banner & {
        width: auto;
        max-width: 200px;
        justify-self: end;
    }
}

.search__options {
    display: none;
}

.search{
    border-radius: 50px;
}

.search__field {
    height: 30px;
    padding: 10px 0px 7px;
}

.search__text{
    padding: 0 20px;
    border-left: none;
}

.button--search {
    background: url("/Content/themes/eiss/images/search-icon.jpg") no-repeat 0 0;
    background-size: 15px 15px;
    height: 15px;
}

.search__submit {
    flex: 0 0 20px;
}

@media only screen and (min-width: 820px) {
    .title-wrap {
        padding-top: 6em;

        .is--logged-in & {
            padding-top: 7.5em;
        }
    }
}

@media only screen and (max-width: 819.98px) {
    .account-header__nav {
        background-color: #aaaaaa;

        a {
            color: #fff;
        }
    }
}

@media only screen and (max-width: 620px) {
    .top-header {
        background-color: unset;
    }

    .header-top__note--mobile {
        width: 100%;
        grid-column: 1 / span 2;
        text-align: center;
        padding: 8px;
        background-color: $eiss-primary;
        color: #fff;

        .is--logged-in & {
            padding-top: 8px;
        }
    }

    .locator-feature {
        grid-row: 2;
    }

    .header-top-sites-container {
        grid-row: 4;
        grid-column: 1 / span 2;
    }

    .header-top-site-items {
        display: grid;
        grid-template-columns: 1fr 1fr;        
    }

    .header-top-site-item {
        flex: 1 1 50%;
        text-align: center;
        margin: 0;        
    }

    .parent-site {
        background-color: $background-light;
    }

    .header-top__countdown {
        grid-row: 5;
        grid-column: 1 / span 2;
        text-align: center;
        color: unset;
        padding: 5px 0;
    }

    .site-logo {
        display: none;
    }

    .site-logo--mobile {
        grid-row: 6;
        padding: 20px 10px 5px;
        margin: 0 auto;
        display: block;
        grid-column: 1 / span 2;

        .site-logo__img {
            margin: 0 auto;
            width: 75%;
        }
    }

    .utility-bar {
        grid-row: 7;
        grid-column: 1 / span 2;
        background-color: #fff;
        padding: 0px;
    }

    .utility-bar__inner {
        grid-template-columns: 80% 20%;
        justify-items: center;
        padding: 0;

        a {
            color: #000;
        }
    }    

    .account-header {
        width: 100%;
        grid-column: 1;
        background-color: #fff;
    }

    .account-header__icon {
        grid-column: 1;
    }

    .account-header__nav {
        a {
            color: #fff;
        }
    }

    .header-cart {
        grid-column: 2;
        justify-self: end;
    }

    .account-header__account-greeting {
        display: none;
    }

    .account-header__header-i-guess {
        display: grid;
        grid-template-columns: auto auto 75%;
        align-items: center;
    }

    .account-header__toggle {
        grid-column: 2;
        grid-row: 1;
        padding: 5px 10px 0 5px;
    }

    .account-header__title {
        grid-column: 3;
        text-align: left;
    }

    .search {
        display: none;
    }

    .title-wrap,
    .is--logged-in .title-wrap,
    .header-banner {
        padding: 0px;
    }

    .login-links {
        grid-column: 1;
        justify-self: start;
        padding-left: 20px;
        color: #000;
    }

    .header-search {
        padding-right: 10px;
        width: 100%;
        grid-column: 2;
    }

    .search,
    .search__text {
        max-width: unset;
    }

    .search__field {
        padding: 10px;
    }
}
//---------------------- End new mobile header -----------------//
