
.site-footer__item{
  .i--twitter,
  .i--facebook,
  .i--linkedin,
  .i--pinterest,
  .i--youtube,
  .i--wordpress,
  .i--feed,
  .i--instagram,
  .i--bangstyle,
  .i--email {
    background: url("/content/themes/eiss/images/social-icons.png") no-repeat;
    background-color: #fff;
    background-size: 52px;
  }
  
  .i--facebook {
    background-position: 0px 0 ;
  }
  
  .i--instagram {
    background-position: -26px 0 ;
  }
}