.colorBar__bar{
    background: $eiss-primary;
    border: none;
    width: 100px;
}

.slider-outer {
    margin: 70px 0;    
    max-width: 1200px;
    margin: 0 auto 60px;
}

.bx-wrapper {
    .bx-pager {
        text-align: right;
    }

    .bx-pager.bx-default-pager a {
        background-color: #fff;
        border: 2px solid $main-font;
        color: rgba(0, 0, 0, 0);
    }

    .bx-pager.bx-default-pager a.active,
    .bx-pager.bx-default-pager a:hover {
        background-color: $main-font;
    }

    .bx-controls-direction a {
        margin-top: -55px;
        width: 19px;
        height: 33px;
        text-indent: -9999px;
    }
}

.secondary-banner-wrap{
  .bx-wrapper {
    .bx-controls-direction {
      display: block; 
    }
  }
  
}

// Shop Now Scroll
.bx-wrapper .bx-controls-direction a {
    background: url("/content/themes/eiss/images/universal-nav-arrows.png") no-repeat 0 0;
    width: 35px;
    height: 35px;
}

.bx-wrapper .bx-next,
.bx-wrapper .bx-next:hover {
  background-position: 100% 0;
}

.bx-wrapper .bx-prev,
.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.main-banner-wrap .bx-wrapper .bx-next {
  right: 20px;
  background-position: 100% 0;
}

.main-banner-wrap .bx-wrapper .bx-prev {
  left: 20px;
  background-position: 0 0;
}

// Home Reviews
.dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: transparent;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #000;
}
  
.dot--is-active,
.dot:hover {
    background-color: #000;
}
  
  .testimonial {
    padding: 40px 25px;
  
    p {
        line-height: 2;
    }
  
    h2 {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 2em;
    }
  }
  

  .text-grid-item {
    background: #F2F0EA;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  
  .about-testimonials-container {
    padding: 40px 20px;
    
  }
  
  .about-header{
    margin: 0 auto .85em;
    color: #000;
    font-size: 26px;
    font-weight: 700;
  }
  
  .testimonial-text{
    font-weight: 400;
    font-size: 16px;
    span{
      font-weight: 700;
    }
  }
  
  .testimonial-item {
    display: none;
  }
  
  .testimonial-active {
    display: block;
  }
  
  .testimonial-reviewer__name {
    font-style: italic;
    color: #000;
  }
  
  
  .testimonials-container {
    display: block;
    flex-wrap: wrap;
    background: #F2F2F2;
    justify-content: center;
    color: #000;
  }
  
  .testimonials-container > div {
    margin: 30px;
    text-align: center;
    font-size: 18px;
  }
  

  .about-bx-wrapper .bx-controls-direction a {
    margin-top: -155px;
  }


.testimonials{
  .bx-wrapper .bx-controls-direction a {
    position: absolute;
    top: 50%;
    outline: 0;
    text-indent: -9999px;
    z-index: 0;
  }

  .bx-wrapper .bx-next,
    .bx-wrapper .bx-next:hover {
    background-position: 100% 0;
    }

    .bx-wrapper .bx-prev,
    .bx-wrapper .bx-prev:hover {
    background-position: 0 0;
    }

    .testimonial {
        background: #fff;
    }
}

.bx-wrapper .bx-pager.bx-default-pager a{
  background: #fff;
}

.testimonial {
  margin: 20px 0;
}

@media only screen and (min-width: 820px) {
  .testimonials-container > div {
    font-size: 22px;
    max-width: 800px;
    margin: 0 auto;
  }

  .testimonials{
    .bx-wrapper .bx-controls-direction a {
        margin-top: -23%;
    }    
  }

  .testimonials-container {
    padding: 50px 0;
  }  
}

@media only screen and (min-width: 1000px) {
  .testimonial {
      p {
          line-height: 1.3;
      }

      h2 {
          padding-top: 0px;
          font-size: 1.5em;
      }
  }

  .testimonials{
    .bx-wrapper .bx-controls-direction a {
      margin-top: -20%;
    }

    .bx-wrapper .bx-next,
    .bx-wrapper .bx-next:hover {
    right: -7%;
    }

    .bx-wrapper .bx-prev,
    .bx-wrapper .bx-prev:hover {
    left: -7%;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .testimonial {
      padding: 25px;

      p {
          line-height: 2;
          min-height: 150px;
          max-width: 800px;
      }

      h2 {
          padding-top: 0;
          font-size: 2em;
      }
  }

  .testimonials{
    .bx-wrapper .bx-controls-direction a {
      margin-top: -180px;
    }
  }
}

@media (max-width: 819.98px) {
  .testimonials-container>div {
    padding: 30px 10px;
  }

  .about-header {
    font-size: 26px;
 }
 .testimonials{
    .bx-wrapper .bx-controls-direction a {
      margin-top: -200px;
    }
  }  
}

@media (max-width: 680px) {
  .secondary-banner-wrap{
    .bx-wrapper {
      .bx-controls-direction a{
        margin-top: -2px;
      }
    }
  }

  .testimonials{
    .bx-wrapper .bx-controls-direction a {
      margin-top: -230px;
    }
  }
}