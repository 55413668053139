.catalogs {
  max-width: 1200px;
  margin: 0 auto;
}

.catalogs__header-inner {
  display: inline-block;
  position: relative;
  top: 10px;
  width: 380px;
  height: 60px;
  background: url("/content/themes/eiss/redesign/images/your-next-move.png")
    no-repeat 0 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.catalog__text::after {
  display: none;
  width: 11px;
  height: 12px;
  margin: 0 0 0 10px;
  background-image: url("/content/themes/eiss/images/catalog-arrow.png");
}

.catalog__text {
  font-size: 1.4em;
  color: $main-font;

  .button{
    background-color: #fff;
    color: $eiss-primary;
    display: block;

    &:hover{
      background-color: #fff;
      text-decoration: underline;
    }
  }
}

.catalogs__list{
  .button{
    text-align: left;
    padding: 12px 0px;
  }
}

.catalogs {
  border-top: 2px solid #D6D6D6;
  border-bottom: 2px solid #D6D6D6;
}


@media only screen and (min-width: 820px) {
  .catalog.catalog--primary {
    padding: 10px 0 0;
  }
}

@media only screen and (min-width: 640px) {
  .catalogs {
    display: block;
    padding-top: 0px;
    margin-bottom: 60px;
    text-align: center;
  }

  .catalogs__text {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 1.4em;
    justify-content: center;
  }

  .catalogs__header {
    display: none;
  }

  .catalogs__list {
    position: relative;
  }

  .catalog:first-child {
    margin-top: 0;
  }

  .catalog__text {
    position: relative;
    align-self: center;
    padding: 0px 10px;
    white-space: nowrap;
    font-size: 26px;
  }

  .catalog__link {
    overflow: visible;
    justify-content: center;
  }

  .catalog__image {
    bottom: -22px;
    left: 0;
    width: 150px;
    height: auto;
    margin-bottom: 0;
  }

  .catalog:last-child {
    border-bottom: none;
  }
}

@media (max-width: 767.98px) {
  .catalogs__header-inner {
    display: none;
  }
}

@media (max-width: 680px) {
  .catalog:last-child {
    border-bottom: none; 
  }

  .catalog.catalog--primary {
    text-align: center;
  }

  .catalogs__header {
    padding: 0px;
  }
}
