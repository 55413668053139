.home-article .article--animation,
.slidewrap a {
  &:after {
    content: "SHOP NOW";
    display: inline-block;
    position: relative;
    top: 0;
    left: 50%;
    opacity: 0;
    background: $eiss-primary;
    color: #fff;
    padding: 12px 50px;
    transition: top 0.5s, opacity 0.5s;
    border-radius: 30px;
    font-family: basic-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    font-size: 13px;
    transform: translateX(-50%);
  }
}

.slidewrap a {
  .slider-caption {
    opacity: 1;
    transition: opacity 0.5s;
    font-weight: 400;
  }

  &:hover {
    text-decoration: none;

    &:after {
      top: -13px;
      opacity: 1;
    }

    .slider-caption {
      opacity: 0 !important;
    }
  }
}

.home-article {
  a:after {
    content: none;
  }

  .home-article__link {
    position: relative;
  }

  .article--animation:after {
    display: none;
    content: "READ NOW";
    position: absolute;
    top: auto;
    left: 0;
    bottom: -13px;
    right: 0;
    padding: 10px 20px;
    transform: translateY(100%);
    transition: bottom 0.5s, opacity 0.5s;
  }  
}

.banner--slider {
  .slidewrap a:after {
    content: "SHOP NOW";
  }

  .slidewrap a:hover:after {
    top: -34px;
    opacity: 1;
  }
}

// Whats New Slider
.bx-wrapper .bx-controls-direction a.disabled {
  display: block;
  z-index: 0;
}

.slider--new{
  .bx-wrapper .bx-next {
    background-position: 100% 0;
  }
}

// Best Seller
.slider--bestseller,.slider--new{
  .bx-wrapper .bx-prev {
    left: 0px;
    z-index: 0;
  }

  .bx-wrapper .bx-next {
    right: 0px;
    z-index: 0;
    background-position: 100% 0;
  }  
}

/*@media (min-width: 680px) {
  .slidewrap img {
    max-width: 230px;
  }
}*/

@media (max-width: 480px) {
  .banner--slider .slidewrap a:after,
  .slidewrap a:after {
    left: 100px;
  }

  .slidewrap a:hover:after {
    left: 100px;
    padding: 10px 20px;
  }

  .banner--slider .slidewrap a:hover:after {
    top: -23px;
  }

  .home-article:hover {
    .article--animation:after {
      left: 0;
    }
  }
}
@media (max-width: 380px) {
  .slidewrap a:after {
    padding: 10px;
    left: 28px;
    font-size: 14px;
  }

  .slidewrap a:hover:after {
    left: 28px;
    padding: 10px;
    font-size: 14px;
  }

  .banner--slider .slidewrap a:after {
    padding: 10px;
    left: 40px;
    font-size: 14px;
  }

  .banner--slider .slidewrap a:hover:after {
    top: -20px;
    font-size: 14px;
  }
}
