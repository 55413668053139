.secondary-banner-wrap {
	max-width: 1200px;
	margin: 60px auto;
}

.secondary-banner-wrap .banner-feature {
	margin-top: 20px;
}

@media only screen and (min-width: 681px) {
	.secondary-banner-wrap .banner-feature {	
		margin-top: 0px;
	}
}