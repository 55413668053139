.about-history {
  margin-top: 60px;
}
.connect-span {
  font-weight: 700;
  color: #000;
}

.history-text {
  color: #000;
}

.why-text {
  color: #000;
}

.connect-p {
  color: #000;
}

.square-p {
  color: #000;
}

.img-text{
  font-weight: 700;
}

@media only screen and (min-width: 681px) {
  .box {
    background-color: #ededed;
  }
}

@media only screen and (min-width: 992px) {
  .main-img {
    position: relative;

    .img-text {
      font-family: $font-titles;
      color: #fff;
      position: absolute;
      top: 60px;
      left: -50px;
      padding: 10px 60px 0px;
      background: #b42025;
      font-size: 35px;
    }
  }

  .page-text {
    padding: 50px 0px;
    color: #b42025;

    .page-text-title {
      text-align: center;
      text-transform: capitalize;
      font-size: 3em;
      font-weight: 700;
    }
  }
  .about-history {
    img {
      max-height: 400px;
    }

    .border-left {
      border-left: 5px solid #b42025;
      padding-right: 3px;

      .history-title {
        color: #000;
        font-size: 2.3em;
        padding-left: 30px;
        font-weight: 700;
      }

      .history-text {
        color: #000;
        font-size: 1.2em;
        line-height: 34px;
        padding: 0 0 0 30px;

        .contract {
          color: #b42025;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .why-eiss {
    .why-section {
      margin: 50px 0;
      .why-title {
        font-family: $font-titles;
        font-size: 2.3em;
        color: #000;
        margin-top: 40px;
        font-weight: 700;
      }
      .why-text-container {
        position: relative;
        margin-bottom: 90px;
        .why-img {
          position: absolute;
          top: 60px;
          right: -835px;
          z-index: 1;
          img {
            height: 750px;
          }
        }
      }
      .why-titleText {
        border: 7px solid #ededed;
        color: #000;
        padding: 20px 242px 80px 80px;
        .why-text {
          color: #000;
          font-size: 1.2em;
        }
      }
    }
  }

  .box-margin > * + * {
    margin-left: 7px;
  }

  .g-6.md-12.sm-12.box.bx-r > * + * {
    margin-right: 7px;
  }

  .box {
    background-color: #ededed;
  }

  .bg-modal,
  .bg-modal2,
  .bg-modal3 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: 1000;
  }

  .modal-content {
    height: 700px;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    position: relative;
  }
  .modal-content2 {
    height: 600px;
    background: #fff;
    border-radius: 20px;
    text-align: center;
    margin-top: 80px;
    padding: 20px;
    position: relative;
  }
  .modal-content3 {
    width: 500px;
    height: 250px;
    background: #fff;
    border-radius: 20px;
    text-align: center;
    margin-top: 125px;
    padding: 20px;
    position: relative;
  }

  .modal-bg {
    img {
      max-height: 650px;
      width: 100%;
    }
  }

  .modal-bg2 {
    img {
      max-height: 580px;
    }
  }

  .modal-bg3 {
    height: 230px;
  }

  .close {
    display: none;
  }

  .close,
  .close2,
  .close3 {
    position: absolute;
    top: 0;
    right: 14px;
    font-size: 2.6em;
    font-weight: 400;
    color: #b7b7b7;
    transform: rotate(45deg);
    cursor: pointer;
  }

  .close2 {
    position: absolute;
    top: -15px;
    right: 14px;
    font-size: 2.6em;
    font-weight: 400;
    color: #b7b7b7;
    transform: rotate(45deg);
    cursor: pointer;
  }

  .contract-text {
    padding: 5px 5px;
    font-size: 1.2em;
    font-weight: 400;
    text-align: left;
  }

  .connect-container {
    margin-bottom: 90px;
    .connect {
      padding: 50px 0px;
      border: 7px solid #ededed;
      .connect-text {
        text-align: center;
        .connect-title {
          font-weight: 700;
          font-size: 3em;
          text-transform: capitalize;
          color: #b42025;
        }
        .connect-p {
          color: #000;
          font-size: 1.2em;
        }
      }
      .all-squares {
        margin-top: 70px;

        .square-container {
          padding: 0px 200px;
          font-weight: 400;

          .square-img {
            text-align: -webkit-center;

            img {
              width: 70px;
            }
          }
          .square-text {
            color: #000;
            margin-top: 15px;
            .square-p {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .boxes .box-btn .button.box-button {
    font-size: 1.3em;
    font-weight: 400;
    width: 75%;
    text-transform: uppercase;
  }
  
}

.boxes {
  margin-bottom: 60px;
}

.boxes .box-btn {
  text-align: center;
  margin-top: 10px;
}

@media (min-width: 1024px) and (max-width: 1198px) {
  .why-eiss .why-section .why-titleText {
    padding: 20px 242px 0px 20px;
  }
}

@media only screen and (max-width: 991.98px) {
  .main-img {
    position: relative;

    .img-text {
      font-family: $font-titles;
      color: #fff;
      position: absolute;
      top: 10px;
      left: 0px;
      padding: 10px 10px 0px;
      background: #b42025;
      font-size: 1.2em;
    }
  }
  .page-text {
    padding: 50px 0px;
    font-style: italic;
    color: #b42025;

    .page-text-title {
      text-align: center;
      text-transform: capitalize;
      font-size: 2em;
    }
  }
  .about-history {
    margin-top: 0px;

    img {
      margin-left: 0px;
    }

    .border-left {
      border-left: 0px solid #b42025;
      .history-title {
        color: #000;
        font-size: 2.3em;
        padding-left: 0px;
        margin-top: 20px;
        margin-bottom: 5px;
        padding: 0 10px;
      }

      .history-text {
        color: #000;
        font-size: 1em;
        line-height: 23px;
        padding: 0;
        padding: 0 10px;
        .contract {
          color: #b42025;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .why-eiss {
    .why-section {
      .why-title {
        margin-top: 50px;
        margin-bottom: 5px;
        font-family: $font-titles;
        font-size: 2.3em;
        color: #000;
      }
      .why-text-container {
        .why-img {
          margin-bottom: 40px;
        }
      }
      .why-titleText {
        border: 0px solid #ededed;
        .why-text {
          font-size: 1em;
        }
      }
    }
  }

  .bg-modal,
  .bg-modal2,
  .bg-modal3 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: 1000;
  }

  .modal-content {
    background: #fff;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    position: relative;
  }
  .modal-content2 {
    background: #fff;
    border-radius: 20px;
    text-align: center;
    margin-top: 80px;
    padding: 20px;
    position: relative;
  }
  .modal-content3 {
    width: 500px;
    height: 250px;
    background: #fff;
    border-radius: 20px;
    text-align: center;
    margin-top: 125px;
    padding: 20px;
    position: relative;
  }

  .modal-bg {
    img {
      max-height: 650px;
      width: 100%;
    }
  }

  .modal-bg2 {
    img {
      max-height: 580px;
    }
  }

  .modal-bg3 {
    height: 230px;
  }

  .close,
  .close2,
  .close3 {
    position: absolute;
    top: 0;
    right: 14px;
    font-size: 2.6em;
    font-weight: 400;
    color: #b7b7b7;
    transform: rotate(45deg);
    cursor: pointer;
  }

  .bg-modal,
  .bg-modal2,
  .bg-modal3 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: 1000;
  }

  .modal-content {
    background: #fff;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    position: relative;
  }
  .modal-content2 {
    background: #fff;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    position: relative;
  }
  .modal-content3 {
    width: 500px;
    height: 220px;
    background: #fff;
    border-radius: 20px;
    text-align: center;
    margin-top: 125px;
    padding: 20px;
    position: relative;
  }

  .modal-bg {
    img {
      width: 100%;
    }
  }

  .modal-bg3 {
    height: 230px;
  }

  .close,
  .close2,
  .close3 {
    padding-top: 10px;
    position: absolute;
    top: 0;
    right: 14px;
    font-size: 2.6em;
    font-weight: 400;
    color: #b7b7b7;
    transform: rotate(45deg);
    cursor: pointer;
  }

  .contract-text {
    padding: 20px 10px;
    font-size: 1.2em;
    font-weight: 400;
    text-align: left;
  }

  .connect-container {
    margin-bottom: 90px;
    .connect {
      border: 7px solid #ededed;
      .connect-text {
        text-align: center;
        .connect-title {
          font-weight: 400;
          font-size: 2em;
          text-transform: capitalize;
          font-style: italic;
          color: #b42025;
        }
        .connect-p {
          font-size: 0.9em;
          font-weight: 400;
        }
      }
      .all-squares {
        margin-top: 70px;
        margin-bottom: 70px;
        .square-container {
          padding: 0px 0px;
          font-weight: 400;
          .square-img {
            text-align: -webkit-center;
            img {
              width: 70px;
            }
          }
          .square-text {
            margin-top: 15px;
            .square-p {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 991px) {
  .page-content {
    line-height: 1.4em;
  }
  .why-eiss .why-section .why-titleText {
    margin-bottom: 30px;
    border: 5px solid #ededed;
    padding: 0 20px;
  }

  .connect-container {
    margin-bottom: 90px;
    .connect {
      border: 7px solid #ededed;
      .connect-text {
        text-align: center;
        .connect-title {
          font-weight: 400;
          font-size: 3em;
          text-transform: capitalize;
          font-style: italic;
          color: #b42025;
        }
        .connect-p {
          font-size: 0.9em;
          font-weight: 400;
        }
      }
      .all-squares {
        margin-top: 70px;
        margin-bottom: 70px;
        .square-container {
          padding: 0px 0px;
          font-weight: 400;
          .square-img {
            text-align: -webkit-center;
            img {
              width: 70px;
            }
          }
          .square-text {
            margin-top: 15px;
            .square-p {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 568px) {
  .why-eiss .why-section .why-title {
    margin-top: 0px !important;
  }
  .why-eiss .why-section .why-text-container .why-img {
    top: 110px;
  }
}

@media (min-width: 320px) and (max-width: 568px) {
  .modal-content3 {
    height: 300px;
    padding: 10px;
  }
  .close3 {
    top: -13px;
  }
}

@media (min-width: 375px) and (max-width: 812px) {
  .modal-content3 {
    height: 225px;
  }
  .close3 {
    padding-top: 20px;
  }
}

@media (min-width: 0px) and (max-width: 568px) {
  .about-history {
    margin-top: 0px;
    img {
      margin-left: 0px;
    }

    .border-left {
      border-left: 0px solid #b42025;
      .history-title {
        color: #000;
        font-size: 2.3em;
        padding-left: 0px;
        padding: 0 10px;
        margin-bottom: 5px;
      }

      .history-text {
        color: #000;
        font-size: 1em;
        line-height: 18px;
        padding: 0 10px;
        .contract {
          color: #b42025;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .why-eiss {
    .why-section {
      .why-title {
        margin-top: 50px;
        margin-bottom: 5px;
        font-family: $font-titles;
        font-size: 2.3em;
        color: #000;
        padding: 0 10px;
      }
      .why-text-container {
        .why-img {
          margin-bottom: 40px;
        }
      }
      .why-titleText {
        border: 0px solid #ededed;
        padding: 0;
        .why-text {
          font-size: 1em;
          line-height: 1.4em;
          padding: 0 10px;
        }
      }
    }
  }

  .connect-container {
    margin-bottom: 90px;
    .connect {
      border: 7px solid #ededed;
      .connect-text {
        text-align: center;
        .connect-title {
          font-weight: 400;
          font-size: 2em;
          text-transform: capitalize;
          font-style: italic;
          color: #b42025;
        }
        .connect-p {
          font-size: 0.8em;
          font-weight: 400;
        }
      }
      .all-squares {
        margin-top: 70px;
        margin-bottom: 70px;
        .square-container {
          padding: 0px 0px;
          font-weight: 400;
          .square-img {
            text-align: -webkit-center;
            img {
              width: 70px;
            }
          }
          .square-text {
            margin-top: 15px;
            .square-p {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .modal-content3 {
    width: 500px;
  }
}

// Modals

// --------------------
.home-about {
  margin: 60px 0;
  font-weight: 100;
  background: #000;
}

.home-about__inner {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;

  @media only screen and (min-width: 960px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.home-about__graphic {
  order: 2;
  padding: 0 10px;

  @media only screen and (min-width: 960px) {
    align-self: flex-end;
    width: 50%;
  }
}

.home-about__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0 20px;
}

.home-about__text {
  order: 1;
  padding: 20px;
  color: #fff;
  font-size: 12px;
  line-height: 1.5;

  @media only screen and (min-width: 960px) {
    order: 3;
    width: 50%;
    font-size: 16px;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.home-about__header {
  margin: 0 0 10px;
  padding: 0;
  color: $eiss-secondary;
  font-size: 16px;

  @media only screen and (min-width: 960px) {
    font-size: 22px;
  }
}

.home-services{
  display: flex;
  justify-content: center;
  gap: 15px;
  max-width: 1200px;
  margin: 0 auto;
}

.home-services__top, .home-services__bottom{
  padding: 0 ;
  }

.home-services__boxes {
    background-color: #ededed;
    padding: 40px;
    height: 100%;

    p {
        font-size: 1.3em;
    }

    span {
        color: $eiss-primary;
        font-weight: 700;
    }

    .shop-online__number {
        color: $eiss-primary;
        font-size: 16px;
        font-weight: 700;
    }
}

.home-services__text{
  line-height: 28px;
}

.home-services__btn{
  padding-top: 20px;
}

@media only screen and (max-width: 680px) {
  .home-services{
    display: block;  
  }

  .home-services__top{
	margin-bottom: 40px;
    padding: 0 10px;
  }

  .home-services__bottom{
    padding: 0 10px;
  }

  .boxes .box-btn {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
 }
}

// Media Queries
@media only screen and (min-width: 992px) {
    .connect-container .connect .connect-text {
        padding-top: 100px;
    }
}

@media (max-width: 991.98px) {

    .connect-container .connect .connect-text {
        padding-top: 50px;
    }
}