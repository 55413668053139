.site-nav__item--holiday a {
    color: $eiss-primary;
}

@media only screen and (min-width: 820px) {
  .site-nav {
    background: none;
    max-width: 1500px;
  }

  .site-nav__top {
    color: $main-font;
    text-align: center;
  }

    .site-nav__item--promotions a {
        color: $eiss-primary;
    }

    .sub-nav__col, .sub-nav__col--2, .sub-nav__col--3, .sub-nav__col--4 {
        float: left;
        width: 132px;
    }
}

//------- global mobile changes ------//

.menu-wrap .search {
    display: none;
}

.nav-toggles,
.nav-toggle {
    background-color: #fff;
    justify-self: center;
}

.burger {
    width: 30px;
    height: 23px;
}

.burger__middle {
    background: #000;
    top: 10px;
}

.burger::before,
.burger::after {
    background: #000;
}

@media only screen and (max-width: 620px) {
    .menu-wrap {
        display: grid;
        grid-template-columns: 1fr 4fr;
        padding: 10px 0;

        .search {
            display: flex;
        }
    }
}